<template>
  <div class="appointment">
    <v-card class="border-radius-16" elevation="0">
      <v-card-title class="card-title" style="padding-left: 25px">
        Appointments
      </v-card-title>
      <div v-if="loading" class="text-center mt-4">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <v-list v-else class="border-radius-16">
        <div v-if="!getData.length" class="text-center mt-4">
          No upcoming appointments
        </div>
        <div v-else-if="checkNextAppointment">
          <v-list-group v-for="item in getData" :key="item" :value="false">
            <template v-slot:activator>
              <v-list-item-title class="ml-2">
                {{ item.next }}
              </v-list-item-title>
            </template>
            <v-list-item style="background: #e7f4f5">
              <v-list-item-content>
                <v-list-item-title class="ml-2">
                  {{ item.address1 }}, {{ item.address2 }}
                  <br />
                  {{ item.city }}, {{ item.state }} {{ item.zip }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <img width="18px" height="24px" :src="getIconMapMarker" />
              </v-list-item-action>
            </v-list-item>
            <v-list-item style="background: #e7f4f5">
              <v-list-item-content>
                <v-list-item-title class="ml-2">
                  {{ phoneFormatted(item.phone) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <img width="18px" height="24px" :src="getIconPhone" />
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </div>
        <div v-else>
          <v-list-group v-for="item in getData" :key="item" :value="false">
            <template v-slot:activator>
              <v-list-item-title class="ml-2">
                {{ getTimeFormat(item.start, item.timezone, item.title) }}
              </v-list-item-title>
              <v-list-item-action class="white-space-nowrap item-action">
                {{ item.status }}
              </v-list-item-action>
            </template>
            <v-list-item style="background: #e7f4f5">
              <v-list-item-content>
                <v-list-item-title class="ml-2">
                  {{ item.address1 }}, {{ item.address2 }}
                  <br />
                  {{ item.city }}, {{ item.state }} {{ item.zip }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <img width="18px" height="24px" :src="getIconMapMarker" />
              </v-list-item-action>
            </v-list-item>
            <v-list-item style="background: #e7f4f5">
              <v-list-item-content>
                <v-list-item-title class="ml-2">
                  {{ phoneFormatted(item.phone) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <img width="18px" height="24px" :src="getIconPhone" />
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <Btn
          width="120px"
          class="mb-2"
          style="margin-right: 13px"
          theme="primary"
          @click="showAll"
        >
          Show All
        </Btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { calendar } from '../../sharedPlugin';
import moment from 'moment';
require('moment-timezone');
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isNext: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    moment
  }),
  computed: {
    ...calendar.mapComputed(['selectedDate']),
    checkNextAppointment() {
      let data = this.data;

      let apptts = data
        .map((element) => {
          const startDate = moment(element.start ? element.start : '');
          const today = moment();
          const selectedDate = moment(this.selectedDate);
          const isNext =
            startDate.isAfter(today) && !selectedDate.isSame(startDate, 'day');

          let n_element = {
            ...element,
            isNext: !!isNext
          };
          return n_element;
        })
        .filter((el) => el.isNext);

      return apptts.length > 0 ? true : false;
    },
    getData() {
      let data = this.data;
      const selectedDate = moment(this.selectedDate).format('YYYY-MM-DD');

      let apptts = data.map((element) => {
        let n_element = {
          ...element,
          isNext:
            moment(element.start).format('YYYY-MM-DD') >
              moment().format('YYYY-MM-DD') &&
            moment(element.start).format('YYYY-MM-DD') != selectedDate
              ? true
              : false,
          next:
            'Next appointment: ' + moment(element.start).format('YYYY-MM-DD')
        };
        return n_element;
      });
      return apptts;
    },
    getIconMapMarker() {
      return require('../../assets/svg/map_marker.svg');
    },
    getIconPhone() {
      return require('../../assets/svg/phone.svg');
    }
  },
  methods: {
    getNextTimeFormat(date) {
      let start = moment(date).format('MMMM DD, YYYY');

      return 'Next appointment: ' + start;
    },
    getTimeFormat(date, timezone, title) {
      let start = moment(date).format('h:mma');

      var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
      var abbr = '(' + timeZoneAbbr + ')';

      return `${start} ${abbr} - ${title}`;
    },
    phoneFormatted(phone) {
      if (phone) {
        phone = phone.replace(/[^0-9]/g, '') || '';
        phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
          6,
          10
        )}`;
      }
      return phone;
    },
    showAll() {
      this.$router.push({ path: '/appointments' });
    }
  }
};
</script>

<style lang="scss">
.appointment {
  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    height: 41px;
    background: #a0d5d7;
    padding: 4px 4px 0 12px;
  }
  .border-radius-16 {
    border-radius: 16px;
  }
  .item-action {
    font-size: 1rem;
    height: 36px;
    align-items: center;
  }
  .white-space-nowrap {
    white-space: nowrap;
  }
  .v-list-group {
    .v-icon {
      color: #d6366e;
    }
  }
  .v-list-group.v-list-group--active {
    .v-icon {
      color: #1f6070;
    }
  }
}
</style>
