<template>
  <v-container>
    <v-dialog v-model="dialog" persistent scrollable>
      <v-card>
        <v-toolbar dark flat color="#192c53">
          <v-row justify="center">
            <v-toolbar-title class="text-center">
              EULA Agreement Notice
            </v-toolbar-title>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <loading-circle v-if="loading" />
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
            @loaded="loading = false"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-checkbox label="I agree." v-model="agree" @change="checkbox" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pdf from 'vue-pdf';
import { auth } from '../sharedPlugin.js';
import LoadingCircle from '../components/LoadingCircle.vue';

const loadingTask = pdf.createLoadingTask(
  `${process.env.VUE_APP_API_URL}/api/v1/connect/users/EULA`
);

export default {
  components: { pdf, LoadingCircle },
  data() {
    return {
      agree: false,
      loading: true,
      dialog: true,
      src: loadingTask,
      numPages: null
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    ...auth.mapMethods(['acceptEULA']),
    checkbox() {
      if (this.agree) {
        this.acceptEULA().then(() => {
          this.dialog = false;
        });
      }
    }
  },
  mounted() {
    this.src.promise.then((pdf) => (this.numPages = pdf.numPages));
  },
  created() {
    this.dialog = !this.user.acceptedEULA;
  }
};
</script>
