<template>
  <div class="calendar-page">
    <v-row class="ma-6 mt-0 pt-6">
      <v-col
        cols="12"
        md="5"
        lg="5"
        xl="5"
        class="pa-0"
        :class="{ 'pr-6': !isMobile }"
      >
        <Calendar v-model="value" :events="getEvents" />
        <div class="d-flex">
          <div class="pt-2 ml-auto">
            <Btn v-if="!todayIsSelectedDate" theme="primary" @click="goToToday">
              Go To Today
            </Btn>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="7" lg="7" xl="7" class="pa-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <DayMedications :data="todaysMeds" :loading="medicationsLoading" />
          </v-col>
          <v-col cols="12" class="pt-6">
            <Appointments
              :data="nextAppointmentList"
              :loading="appointmentsLoading"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Eula />
  </div>
</template>
<script>
import { calendar } from '../../sharedPlugin';
import Calendar from '../../components/treatmentPlan/Calendar';
import DayMedications from '../../components/treatmentPlan/DayMedications';
import Appointments from '../../components/treatmentPlan/Appointments';
import device from '../../utils/device.js';
import moment from 'moment';
import Eula from '@/components/Eula.vue';
export default {
  components: {
    Calendar,
    DayMedications,
    Appointments,
    Eula
  },
  computed: {
    ...calendar.mapComputed([
      'selectedDate',
      'medications',
      'todaysMeds',
      'medicationsLoading',
      'appointments',
      'appointmentsLoading'
    ]),
    isMobile() {
      return window.innerWidth < 960 || device === 'mobile';
    },
    value: {
      get() {
        return moment(this.selectedDate).format('YYYY-MM-DD');
      },

      set(val) {
        let d = new Date(val);
        d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
        this.setSelectedDate(d);
        this.getMedsAsync();
      }
    },
    getSelectedDate() {
      return moment(this.value).format('MMMM DD, YYYY');
    },
    getEvents() {
      let events = [];
      this.medications.forEach((item) => {
        events.push(moment(item.end).format('YYYY-MM-DD'));
      });
      this.appointments.forEach((item) => {
        events.push(moment(item.end).format('YYYY-MM-DD'));
      });
      return events;
    },
    todayIsSelectedDate() {
      return moment().format('MMMM DD, YYYY') === this.getSelectedDate;
    },
    nextAppointmentList() {
      let appointments = this.appointments.filter(
        (el) =>
          moment(el.start).format('YYYY-MM-DD') ==
          moment(this.value).format('YYYY-MM-DD')
      );

      if (!appointments || (appointments && appointments.length == 0)) {
        appointments = this.appointments.filter(
          (el) =>
            el.start.valueOf() >= moment().valueOf() &&
            moment(el.start).format('YYYY-MM-DD') >=
              moment(this.value).format('YYYY-MM-DD')
        );
      }
      return appointments;
    }
  },
  methods: {
    ...calendar.mapMethods([
      'setSelectedDate',
      'getMedsAsync',
      'pullAppointmentsAsync'
    ]),
    goToToday() {
      let d = new Date();
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      this.setSelectedDate(d);
    }
  },
  created() {
    this.getMedsAsync();
    this.pullAppointmentsAsync();
  }
};
</script>

<style lang="scss">
.calendar-page {
  height: 100vh;
  background: #e1e1e1 !important;
}
</style>
