<template>
  <div class="calendar">
    <v-date-picker
      width="100%"
      v-model="value"
      show-adjacent-months
      color="#a0d5d7"
      :events="events"
      event-color="#d6366e"
    ></v-date-picker>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {},
    events: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data: () => ({
    moment,
    date: null
  }),
  computed: {
    currentDate() {
      return moment().format('YYYY-MM-DD');
    }
  },
  watch: {
    value(value) {
      this.$emit('input', value);
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.calendar {
  .v-picker {
    border-radius: 16px;
  }
  .v-picker__title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000;
  }
  .v-date-picker-title__date {
    font-weight: 600;
    font-size: 26px;
    line-height: 39px;
  }
  .v-date-picker-header {
    height: 60px;
  }
  .v-date-picker-header__value {
    .accent--text {
      button:hover {
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }
  .v-date-picker-table__events {
    div {
      margin-top: 17px;
      background-color: rgb(214, 54, 110);
      border-color: rgb(214, 54, 110);
    }
  }
  .v-date-picker-table__current {
    color: #000 !important;
    background: #cacaca;
    border: none;
  }
  .v-date-picker-table {
    height: 100%;
    .v-btn.v-btn--active {
      background: #d6366e !important;
      .v-btn__content {
        color: #fff;
      }
    }
    &--date {
      td {
        padding-bottom: 16px;
      }
    }
  }
}
</style>
