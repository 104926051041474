<template>
  <div class="medication">
    <v-card class="border-radius-16" elevation="0">
      <v-card-title class="card-title" style="padding-left: 25px">
        Medication
      </v-card-title>
      <div v-if="loading" class="text-center mt-4">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-list class="border-radius-16">
          <div v-if="!getData.length" class="text-center mt-4">
            No medications scheduled today
          </div>
          <div v-else>
            <v-list-item v-for="item in getData" :key="item.id">
              <v-list-item-content>
                <v-list-item-title :class="item.new ? 'ml-6' : 'ml-2'">
                  <div v-if="item.new" class="events">
                    <div></div>
                  </div>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="item.video" icon @click="goToVideo(item)">
                  <img
                    width="30px"
                    height="30px"
                    :src="require('../../assets/svg/play.svg')"
                  />
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </div>
      <v-card-actions>
        <v-spacer />
        <Btn
          width="120px"
          class="mb-2"
          style="margin-right: 13px"
          theme="primary"
          @click="showAll"
        >
          Show All
        </Btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { calendar } from '../../sharedPlugin';
//import moment from 'moment';
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...calendar.mapComputed(['selectedDate']),
    getData() {
      this.setPastMeds(this.data);
      let data = this.data;
      return data;
    }
  },
  methods: {
    ...calendar.mapMethods(['setPastMeds']),
    goToVideo(item) {
      if (item && item.video && item.video.link) {
        window.open(item.video.link);
      }
    },
    showAll() {
      this.$router.push({ path: '/medications' });
    }
  }
};
</script>

<style lang="scss">
.medication {
  .card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    height: 41px;
    background: #a0d5d7;
    padding: 4px 4px 0 12px;
  }
  .border-radius-16 {
    border-radius: 16px;
  }
  .item-action {
    font-size: 1rem;
    height: 36px;
    align-items: center;
  }
  .v-list-group__header__append-icon {
    .v-icon {
      color: rgb(214, 54, 110) !important;
    }
  }
  .events {
    height: 8px;
    margin-top: -1px;
    left: 25px;
    position: absolute;
    text-align: left;
    white-space: pre;
    width: 100%;
    div {
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;
      background-color: rgb(214, 54, 110);
    }
  }
}
</style>
